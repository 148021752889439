import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import LandingPage from "./components/LandingPage";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" Component={LandingPage} />
          <Route path="/social" Component={LandingPage} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
