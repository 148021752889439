import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // GitHub-flavored Markdown
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdFolder,
  MdInsertDriveFile,
  MdMail,
  MdPhone,
  MdLocationOn,
  MdClose,
  MdLink,
  MdChat,
} from "react-icons/md";
import { initialise } from '@open-ic/openchat-xframe';




interface Note {
  filename: string;
  content: string;
}

interface FolderItem {
  name: string;
  type: string;
  children?: FolderItem[];
}

const Sidebar = ({ folders, activeNote, onNoteClick }: any) => (
  <div className="sidebar">
    <h2>Contents</h2>
    <FolderStructure folders={folders} onNoteClick={onNoteClick} />
  </div>
);


const FloatingContact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="fixed">
      <button
        className="profile-button"
        onClick={toggleOpen}
        aria-label={isOpen ? "Close contact information" : "Open contact information"}
      >
        <img
          src="https://pbs.twimg.com/profile_images/1549178170531774464/o8grCU1a_400x400.jpg"
          alt="Profile picture"
        />
      </button>

      <div className={`contact-card ${isOpen ? 'open' : ''}`}>
        <button className="close-button" onClick={toggleOpen} aria-label="Close contact information">
          <MdClose />
        </button>
        <h3 className="contact-name">Mitch Kurtzman</h3>
        <div className="contact-info">
          <MdLocationOn />
          <span>USA</span>
        </div>

        {/* New Social Links with ellipses */}
        <div className="contact-info">
          <MdLink />
          <a href="https://www.nuance.xyz/mitch" target="_blank" rel="noopener noreferrer">
            nuance.xyz/mitch
          </a>
        </div>
        <div className="contact-info">
          <MdLink />
          <a href="https://x.com/EnterTheChain" target="_blank" rel="noopener noreferrer">
            x.com/EnterTheChain
          </a>
        </div>
        <div className="contact-info">
          <MdLink />
          <a href="https://github.com/MITCHELLKURTZMAN" target="_blank" rel="noopener noreferrer">
            github.com/MITCHELLKURTZMAN
          </a>
        </div>
      </div>
    </div>
  );
};

// OpenChat Embed Component
const OpenChatEmbed = ({ initialPath }: { initialPath?: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const initializeOpenChat = async () => {
      if (iframeRef.current) {
        try {
          const client = await initialise(iframeRef.current, {
            targetOrigin: 'https://oc.app',
            initialPath: initialPath || '/community/d5h7l-6qaaa-aaaaf-bm23a-cai/channel/151878917134012960231027894823326621705',
            theme: {
              name: 'minimal-test',
              base: 'light',
              overrides: {
                primary: "red", // Something noticeable
              },
            },

            settings: {
              disableLeftNav: false,  // Keep the left navigation enabled.
            },
          });

          // Optional: Bind some navigation to your app.
          // client.changePath('/community/new-channel');

        } catch (error) {
          console.error('Failed to initialize OpenChat:', error);
        }
      }
    };
    initializeOpenChat();

  }, [initialPath]);

  return (
    <iframe
      ref={iframeRef}
      title="OpenChat"
      frameBorder="0"
      style={{
        width: '100%',
        height: '600px',
        borderRadius: '8px',
        border: '2px solid #D4A373',  // Matches sidebar color for a cohesive frame.
      }}
    />
  );
};




const LandingPage = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [folders, setFolders] = useState<FolderItem[]>([]);
  const [activeNote, setActiveNote] = useState<string | null>(null);
  const [noteContent, setNoteContent] = useState<string>("");

  const handleNoteClick = (noteId: string) => {
    setActiveNote(noteId);
    const selectedNote = notes.find((note) => note.filename.includes(noteId));
    if (selectedNote) setNoteContent(selectedNote.content);
  };


  useEffect(() => {
    const loadNotes = async (retries = 5, delay = 500) => {
      try {
        const response = await fetch("./fileList.json");
        if (!response.ok) throw new Error("Network response was not ok");

        const filenames: string[] = await response.json();
        console.log("Fetched filenames:", filenames);

        const loadedNotes = await Promise.all(
          filenames.map(async (filename) => {
            const fetchWithRetry = async (attemptsLeft: number, currentDelay: number): Promise<string> => {
              try {
                const fileResponse = await fetch(`/${filename}`);
                if (!fileResponse.ok) throw new Error(`Error fetching ${filename}`);

                const textContent = await fileResponse.text();

                // Check if the content is wrapped in HTML tags
                if (textContent.startsWith("<!DOCTYPE html>") || textContent.startsWith("<html>")) {
                  const parser = new DOMParser();
                  const parsedHTML = parser.parseFromString(textContent, "text/html");
                  return parsedHTML.querySelector("pre")?.textContent || "Content not found";
                } else {
                  return textContent;
                }
              } catch (error) {
                if (attemptsLeft <= 1) throw error;
                console.warn(`Retrying fetch for ${filename}... (${retries - attemptsLeft + 1}/${retries})`);
                await new Promise((resolve) => setTimeout(resolve, currentDelay));
                return fetchWithRetry(attemptsLeft - 1, currentDelay * 2); // Exponential backoff
              }
            };

            const content = await fetchWithRetry(retries, delay);
            return { filename, content };
          })
        );

        setNotes(loadedNotes);
        setFolders(generateFolderStructure(filenames));
        if (loadedNotes.length > 0) {
          const defaultNote = loadedNotes[0];
          setActiveNote(defaultNote.filename);
          setNoteContent(defaultNote.content);
        }
      } catch (error) {
        console.error("Error loading notes:", error);
      }
    };

    loadNotes();
  }, []);





  const transformObsidianLinks = (content: string) => {
    content = content.replace(/!\[\[(.*?)\]\]/g, (_, filename) => {
      const imagePath = `/public-notes/images/${filename.trim()}`;
      return `![Image](${imagePath})`;
    });

    content = content.replace(/\[\]\((https?:\/\/[^\s]+)\)/g, (_, url) => {
      return `[External Link](${url})`;
    });

    return content;
  };

  return (
    <div className="wiki-layout">
      <Sidebar folders={folders} activeNote={activeNote} onNoteClick={handleNoteClick} />
      <main className="content-area">
        <h1>{activeNote ? activeNote.replace(".md", "") : "Select a Note"}</h1>
        <section className="notes-container">
          {noteContent && (
            <article className="note">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ node, ...props }) => (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                      {props.children}
                    </a>
                  ),
                }}
              >
                {transformObsidianLinks(noteContent)}
              </ReactMarkdown>
            </article>
          )}
        </section>
        <OpenChatEmbed
          initialPath="/community/d5h7l-6qaaa-aaaaf-bm23a-cai/channel/151878917134012960231027894823326621705"
        />
      </main>
      <FloatingContact /> {/* Floating contact component */}
    </div>
  );
};

// Generate folder structure
function generateFolderStructure(filenames: string[]): FolderItem[] {
  const root: FolderItem[] = [];

  filenames.forEach((path) => {
    const parts = path.replace("public-notes/", "").split("/");
    let currentLevel = root;

    parts.forEach((part, index) => {
      const isFile = index === parts.length - 1 && part.endsWith(".md");
      const existingFolder = currentLevel.find((item) => item.name === part);

      if (!existingFolder) {
        const newItem: FolderItem = isFile
          ? { name: part.replace(".md", ""), type: "file" }
          : { name: part, type: "folder", children: [] };
        currentLevel.push(newItem);
        if (!isFile) {
          currentLevel = newItem.children!;
        }
      } else if (!isFile) {
        currentLevel = existingFolder.children!;
      }
    });
  });

  return root;
}

function FolderStructure({ folders, onNoteClick }: any) {
  return (
    <ul>
      {folders.map((item: FolderItem, index: number) => (
        <li key={index} className="folder-item">
          {item.type === "folder" ? (
            <FolderItem item={item} onNoteClick={onNoteClick} />
          ) : (
            <FileItem item={item} onNoteClick={onNoteClick} />
          )}
        </li>
      ))}
    </ul>
  );
}

function FolderItem({ item, onNoteClick }: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)} className="folder-button">
        {isOpen ? <MdKeyboardArrowDown className="icon" /> : <MdKeyboardArrowRight className="icon" />}
        <MdFolder className="icon folder-icon" />
        <span>{item.name}</span>
      </button>
      {isOpen && item.children && (
        <FolderStructure folders={item.children} onNoteClick={onNoteClick} />
      )}
    </div>
  );
}

function FileItem({ item, onNoteClick }: any) {
  return (
    <button onClick={() => onNoteClick(item.name)} className="file-button">
      <MdInsertDriveFile className="icon file-icon" />
      <span>{item.name}</span>
    </button>
  );
}

export default LandingPage;
